import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import{ GlobalConstants } from '../common/globalconstants';
@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad {

  currentsoftware = GlobalConstants.currentsoftware;
  constructor(private storage: Storage, private router: Router) {}

  canLoad(route: Route): boolean {
    
    let url: string = route.path;

//alert(url);
// 1 - Patient Login
// 2 - Doctor Login
// 3 - Hospital Login
// 4 - Representative  Login

    if(this.currentsoftware == 1)
    {
      if (url=='app') { return true; }
      if (url=='departmentlist') { return true; }
      if (url=='departmentview/:id') { return true; }
      if (url=='doctorsearchlist/:did/:hid/:rid') { return true; }
      if (url=='doctorsearchview/:id') { return true; }
      if (url=='patientvisitadd/:drid') { return true; }
      if (url=='patientvisitpayment/:drid/:ptid/:viid') { return true; }
      if (url=='edit/:ptid') { return true; }
      if (url=='view/:ptid') { return true; }
      if (url=='add') { return true; }
      if (url=='doctorsearchview/:id') { return true; }
      if (url=='hospitalview/:id') { return true; }
      if (url=='support') { return true; }
      if (url=='signup') { return true; }
      if (url=='tutorial') { return true; }
      if (url=='dashboard') { return true; }
      if (url=='list') { return true; }
      if (url=='doctorsearchlist/:did/:hid/:rid') { return true; }
      if (url=='hospitallist') { return true; }
      if (url=='departmentlist') { return true; }
      if (url=='patschedule') { return true; }
      if (url=='pharmacylist') { return true; }
      if (url=='laboratorylist') { return true; }
      if (url=='signin') { return true; }
      if (url=='login') { return true; }

      if (url=='patientvisitconsultation/:viid/:uid/:pid/:did') { return true; }
      if (url=='patientvisitprescription/:viid/:uid') { return true; }
      if (url=='patientvisitedit/:viid/:uid') { return true; }
      if (url=='patientvisiteditrefund/:viid/:uid') { return true; }
      if (url=='patientvisitbill/:viid/:uid') { return true; }
      if (url=='pdfviewer/:id/:viid/:uid') { return true; }
      if (url=='docviewer/:id/:viid/:uid') { return true; }
      if (url=='picviewer/:id/:viid/:uid') { return true; }

      if (url=='advertisementlist') { return true; }
      if (url=='advertisementadd') { return true; }
      if (url=='advertisementview') { return true; }
      if (url=='advertisementedit') { return true; }

      if (url=='advertisementpublishedlist') { return true; }
      if (url=='advertisementpublishededit/:id') { return true; }
      if (url=='advertisementpublishedview/:id') { return true; }
      if (url=='advertisementbillview/:id') { return true; }
      if (url=='advertisementedit/:id') { return true; }
      if (url=='bjadsview/:id') { return true; }

      if (url=='notifications') { return true; }
      if (url=='notificationsview/:id') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }

      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      
    }

    if(this.currentsoftware == 2)
    {
      if (url=='signin') { return true; }
      if (url=='login') { return true; }
      if (url=='app') { return true; }
      if (url=='doctordashboard') { return true; }
      if (url=='tutorial') { return true; }
      if (url=='signup') { return true; }
      if (url=='support') { return true; }
      if (url=='doctorview/:id') { return true; }
      if (url=='doctoredit/:id') { return true; }
      if (url=='medicalcounciledit') { return true; }
      if (url=='medicalcouncilview') { return true; }
      if (url=='bankaccountview') { return true; }
      if (url=='bankaccountedit') { return true; }
      if (url=='prescriptiontemplate') { return true; }
      if (url=='prescriptiontemplateadd') { return true; }
      if (url=='prescriptiontemplateedit/:id') { return true; }
      if (url=='prescriptiontemplatedelete/:id') { return true; }


      if (url=='waitingroom/:id') { return true; }
      if (url=='clinic/:id/:cid/:hid') { return true; }
      if (url=='drhospital') { return true; }
      if (url=='drhospitaledit/:id') { return true; }
      if (url=='drhospitaldelete/:id') { return true; }



      if (url=='advertisementlist') { return true; }
      if (url=='advertisementadd') { return true; }
      if (url=='advertisementview') { return true; }
      if (url=='advertisementedit') { return true; }

      if (url=='advertisementpublishedlist') { return true; }
      if (url=='advertisementpublishededit/:id') { return true; }
      if (url=='advertisementpublishedview/:id') { return true; }
      if (url=='advertisementbillview/:id') { return true; }
      if (url=='advertisementedit/:id') { return true; }


      if (url=='drprescriptionview/:id/:uid') { return true; }
      if (url=='notifications') { return true; }
      if (url=='notificationsview/:id') { return true; }


      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
    }

    if(this.currentsoftware == 3)
    {
      if (url=='signin') { return true; }
      if (url=='login') { return true; }
      if (url=='app') { return true; }
      if (url=='hospitaldashboard') { return true; }
      if (url=='tutorial') { return true; }
      if (url=='signup') { return true; }
      if (url=='support') { return true; }
      if (url=='hospschedule') { return true; }
      if (url=='hosp-billinglist') { return true; }

      if (url=='hosp-prescriptionlist') { return true; }
      if (url=='hosp-patientlist') { return true; }
      if (url=='hosp-doctorslist') { return true; }
      if (url=='hosp-hospitallist') { return true; }
      if (url=='hosp-pharmacylist') { return true; }

      if (url=='hosp-laboratorylist') { return true; }
      if (url=='hosp-advertisementlist') { return true; }
      if (url=='hosp-advertisementadd') { return true; }
      if (url=='settings') { return true; }
      if (url=='hosp-doctorsadd') { return true; }

      if (url=='hosp-hospitaladd') { return true; }
      if (url=='hosp-pharmacyadd') { return true; }
      if (url=='hosp-laboratoryadd') { return true; }
      if (url=='hosp-advertisementview/:id') { return true; }
      if (url=='hosp-advertisementedit/:id') { return true; }

      if (url=='hosp-advertisementpay/:id') { return true; }
      if (url=='hosp-laboratoryview/:id') { return true; }
      if (url=='hosp-laboratoryedit/:id') { return true; }
      if (url=='hosp-laboratorypay/:id') { return true; }
      if (url=='hosp-pharmacyview/:id') { return true; }

      if (url=='hosp-pharmacyedit/:id') { return true; }
      if (url=='hosp-pharmacypay/:id') { return true; }
      if (url=='hosp-hospitalview/:id') { return true; }
      if (url=='hosp-hospitaledit/:id') { return true; }
      if (url=='hosp-hospitalpay/:id') { return true; }

      if (url=='hosp-advertisementsearchlist') { return true; }
      if (url=='advertisementedit/:id') { return true; }
      if (url=='hosp-advertisementpublishedview/:id') { return true; }
      if (url=='hosp-advertisementbillview/:id') { return true; }

      if (url=='notifications') { return true; }
      if (url=='notificationsview/:id') { return true; }

      if (url=='app') { return true; }

      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
    }

    
    
    if(this.currentsoftware == 4)
    {
      if (url=='signin') { return true; }
      if (url=='login') { return true; }
      if (url=='app') { return true; }
      if (url=='representativedashboard') { return true; }
      if (url=='tutorial') { return true; }
      if (url=='signup') { return true; }
      if (url=='support') { return true; }

      if (url=='rep-doctorslist') { return true; }
      if (url=='rep-hospitallist') { return true; }
      if (url=='rep-pharmacylist') { return true; }
      if (url=='rep-laboratorylist') { return true; }
      if (url=='rep-advertisementlist') { return true; }



      if (url=='rep-advertisementadd') { return true; }
      if (url=='adddoctorslist') { return true; }
      if (url=='addinstitutionlist') { return true; }
      if (url=='adddoctorsadd') { return true; }
      if (url=='addinstitutionadd') { return true; }

      
      if (url=='rep-doctorsadd/:id') { return true; }
      if (url=='rep-hospitaladd/:id') { return true; }
      if (url=='rep-pharmacyadd/:id') { return true; }
      if (url=='rep-laboratoryadd/:id') { return true; }
      if (url=='rep-advertisementsearchlist') { return true; }
      if (url=='advertisementedit/:id') { return true; }
      if (url=='rep-advertisementedit/:id') { return true; }

      
      if (url=='rep-hospadvertisementpublishedview/:id') { return true; }
      if (url=='rep-hospadvertisementbillview/:id') { return true; }
      if (url=='hospitalview/:id') { return true; }
      if (url=='rep-hospitaledit/:id') { return true; }
      if (url=='rep-hospitalview/:id') { return true; }
      if (url=='rep-pharmacyview/:id') { return true; }
      if (url=='rep-pharmacyedit/:id') { return true; }


      if (url=='rep-laboratoryview/:id') { return true; }
      if (url=='rep-laboratoryedit/:id') { return true; }
      if (url=='rep-doctorsview/:id') { return true; }
      if (url=='rep-doctorsedit/:id') { return true; }
      if (url=='rep-hospitalpay/:id') { return true; }

      if (url=='rep-pharmacypay/:id') { return true; }
      if (url=='rep-laboratorypay/:id') { return true; }
      if (url=='hosphospitalearnings') { return true; }
      if (url=='pharmacyearnings') { return true; }
      if (url=='laboratoryearnings') { return true; }
      if (url=='advertisementearnings') { return true; }
      
      if (url=='consultationearnings') { return true; }
      if (url=='notifications') { return true; }
      if (url=='notificationsview/:id') { return true; }

      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
    }


    if(this.currentsoftware == 5)
    {
      if (url=='signin') { return true; }
      if (url=='login') { return true; }
      if (url=='app') { return true; }
      if (url=='accountsuser') { return true; }
      if (url=='tutorial') { return true; }
      if (url=='signup') { return true; }
      if (url=='support') { return true; }

      if (url=='callamount') { return true; }
      if (url=='calltransaction') { return true; }
      if (url=='softamount') { return true; }
      if (url=='softtransaction') { return true; }
      if (url=='adsamount') { return true; }
      if (url=='adstransaction') { return true; }

      if (url=='notifications') { return true; }
      if (url=='notificationsview/:id') { return true; }
      
      if (url=='app') { return true; }        
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
            
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
    }


    
    if(this.currentsoftware == 6)
    {
      if (url=='signin') { return true; }
      if (url=='login') { return true; }
      if (url=='app') { return true; }
      if (url=='adminuser') { return true; }
      if (url=='tutorial') { return true; }
      if (url=='signup') { return true; }
      if (url=='support') { return true; }
      if (url=='admindepartmentlist') { return true; }
      if (url=='subscriptionlist') { return true; }
      if (url=='pricemasterlist') { return true; }
      if (url=='adspricelist') { return true; }
      if (url=='admindepartmentview/:id') { return true; }
      if (url=='admindepartmentedit/:id') { return true; }
      if (url=='admindepartmentadd') { return true; }

      if (url=='subscriptionadd') { return true; }
      if (url=='subscriptionedit/:id') { return true; }
      if (url=='subscriptionview/:id') { return true; }

      if (url=='pricemasteradd') { return true; }
      if (url=='pricemasterview/:id') { return true; }
      if (url=='pricemasteredit/:id') { return true; }

      if (url=='adspriceadd') { return true; }
      if (url=='adspriceedit/:id') { return true; }
      if (url=='adspriceview/:id') { return true; }

      if (url=='templatelist') { return true; }
      if (url=='templateadd') { return true; }
      if (url=='templateview/:id') { return true; }

      if (url=='templateedit/:id') { return true; }
      if (url=='chatbotlist') { return true; }
      if (url=='helplist') { return true; }

      if (url=='chatbotadd') { return true; }
      if (url=='chatbotedit/:id') { return true; }
      if (url=='helpedit/:id') { return true; }

      if (url=='helpadd') { return true; }
      if (url=='settingslist') { return true; }
      if (url=='settingsedit/:id') { return true; }
      if (url=='settingsview/:id') { return true; }
      if (url=='settingsadd') { return true; }

      if (url=='notifications') { return true; }
      if (url=='notificationsview/:id') { return true; }
      
      if (url=='app') { return true; }


      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }

      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }

      if (url=='app') { return true; }
      if (url=='app') { return true; }
      if (url=='app') { return true; }
    }




// 1 - Patient Login
// 2 - Doctor Login
// 3 - Hospital Login
// 4 - Representative  Login
    return false; 
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import{ GlobalConstants } from './common/globalconstants';



const routes: Routes = [
 
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
    ,canLoad: [CheckTutorial]
  },
 
    {
    path: 'dashboard',
    loadChildren: () => import('./pages/patient/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'bjadsview/:id',
    loadChildren: () => import('./pages/patient/bjadsview/templateview.module').then( m => m.TemplateviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'add',
    loadChildren: () => import('./pages/patient/add/add.module').then( m => m.AddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/patient/list/list.module').then( m => m.ListPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'view/:ptid',
    loadChildren: () => import('./pages/patient/view/view.module').then( m => m.ViewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'edit/:ptid',
    loadChildren: () => import('./pages/patient/edit/edit.module').then( m => m.EditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'delete/:ptid',
    loadChildren: () => import('./pages/patient/delete/delete.module').then( m => m.DeletePageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'searchdr',
    loadChildren: () => import('./pages/patient/searchdr/searchdr.module').then( m => m.SearchdrPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'patientvisitadd/:drid',
    loadChildren: () => import('./pages/patient/patientvisitadd/patientvisitadd.module').then( m => m.PatientvisitaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'patientvisitpayment/:drid/:ptid/:viid',
    loadChildren: () => import('./pages/patient/patientvisitpayment/patientvisitpayment.module').then( m => m.PatientvisitpaymentPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'patientvisitedit/:viid/:uid',
    loadChildren: () => import('./pages/patient/patientvisitedit/patientvisitedit.module').then( m => m.PatientvisiteditPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'patientvisiteditrefund/:viid/:uid',
    loadChildren: () => import('./pages/patient/patientvisiteditrefund/patientvisitedit.module').then( m => m.PatientvisiteditPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'patientvisitview/:viid/:uid',
    loadChildren: () => import('./pages/patient/patientvisitview/patientvisitview.module').then( m => m.PatientvisitviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'selectdr',
    loadChildren: () => import('./pages/patient/selectdr/selectdr.module').then( m => m.SelectdrPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'patschedule',
    loadChildren: () => import('./pages/patient/patschedule/patschedule.module').then( m => m.PatschedulePageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hospital',
    loadChildren: () => import('./pages/patient/hospital/hospital.module').then( m => m.HospitalPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'departmentlist',
    loadChildren: () => import('./pages/common/departmentlist/departmentlist.module').then( m => m.DepartmentlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'departmentview/:id',
    loadChildren: () => import('./pages/common/departmentview/departmentview.module').then( m => m.DepartmentviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'departmentadd',
    loadChildren: () => import('./pages/common/departmentadd/departmentadd.module').then( m => m.DepartmentaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'departmentedit/:id',
    loadChildren: () => import('./pages/common/departmentedit/departmentedit.module').then( m => m.DepartmenteditPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'hospitaladd',
    loadChildren: () => import('./pages/hospital/hospitaladd/hospitaladd.module').then( m => m.HospitaladdPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hospitalview/:id',
    loadChildren: () => import('./pages/hospital/hospitalview/hospitalview.module').then( m => m.HospitalviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hospitaledit/:id',
    loadChildren: () => import('./pages/hospital/hospitaledit/hospitaledit.module').then( m => m.HospitaleditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hospitallist',
    loadChildren: () => import('./pages/hospital/hospitallist/hospitallist.module').then( m => m.HospitallistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'doctoradd',
    loadChildren: () => import('./pages/doctor/doctoradd/doctoradd.module').then( m => m.DoctoraddPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'doctorlist',
    loadChildren: () => import('./pages/doctor/doctorlist/doctorlist.module').then( m => m.DoctorlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'doctorsearchlist/:did/:hid/:rid',
    loadChildren: () => import('./pages/doctor/doctorsearchlist/doctorsearchlist.module').then( m => m.DoctorsearchlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'doctorsearchview/:id',
    loadChildren: () => import('./pages/doctor/doctorsearchview/doctorsearchview.module').then( m => m.DoctorsearchviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'patientvisitbill/:viid/:uid',
    loadChildren: () => import('./pages/patient/patientvisitbill/patientvisitbill.module').then( m => m.PatientvisitbillPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'patientvisitconsultation/:viid/:uid/:pid/:did',
    loadChildren: () => import('./pages/patient/patientvisitconsultation/patientvisitconsultation.module').then( m => m.PatientvisitconsultationPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'patientvisitprescription/:viid/:uid',
    loadChildren: () => import('./pages/patient/patientvisitprescription/patientvisitprescription.module').then( m => m.PatientvisitprescriptionPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'laboratoryadd',
    loadChildren: () => import('./pages/advertisement/laboratoryadd/laboratoryadd.module').then( m => m.LaboratoryaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'laboratoryview/:id',
    loadChildren: () => import('./pages/advertisement/laboratoryview/laboratoryview.module').then( m => m.LaboratoryviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'laboratoryedit/:id',
    loadChildren: () => import('./pages/advertisement/laboratoryedit/laboratoryedit.module').then( m => m.LaboratoryeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'laboratorylist',
    loadChildren: () => import('./pages/advertisement/laboratorylist/laboratorylist.module').then( m => m.LaboratorylistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pharmacyadd',
    loadChildren: () => import('./pages/advertisement/pharmacyadd/pharmacyadd.module').then( m => m.PharmacyaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pharmacyview/:id',
    loadChildren: () => import('./pages/advertisement/pharmacyview/pharmacyview.module').then( m => m.PharmacyviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pharmacyedit/:id',
    loadChildren: () => import('./pages/advertisement/pharmacyedit/pharmacyedit.module').then( m => m.PharmacyeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pharmacylist',
    loadChildren: () => import('./pages/advertisement/pharmacylist/pharmacylist.module').then( m => m.PharmacylistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hospschedule',
    loadChildren: () => import('./pages/hospital/schedule/schedule.module').then( m => m.SchedulePageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'doctordashboard',
    loadChildren: () => import('./doctor/advertisement/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'doctorview/:id',
    loadChildren: () => import('./doctor/advertisement/doctorview/doctorview.module').then( m => m.DoctorviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'doctoredit/:id',
    loadChildren: () => import('./doctor/advertisement/doctoredit/doctoredit.module').then( m => m.DoctoreditPageModule)
    ,canLoad: [CheckTutorial]
  },
  
  {
    path: 'hospitaldashboard',
    loadChildren: () => import('./hospital/advertisement/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'representativedashboard',
    loadChildren: () => import('./representative/advertisement/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'accountsuser',
    loadChildren: () => import('./accounts/advertisement/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adminuser',
    loadChildren: () => import('./admin/advertisement/dashboard/dashboard.module').then( m => m.DashboardPageModule)
    ,canLoad: [CheckTutorial]
  },


  {
    path: 'medicalcounciledit',
    loadChildren: () => import('./doctor/advertisement/medicalcounciledit/medicalcounciledit.module').then( m => m.MedicalcouncileditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'medicalcouncilview',
    loadChildren: () => import('./doctor/advertisement/medicalcouncilview/medicalcouncilview.module').then( m => m.MedicalcouncilviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'bankaccountedit',
    loadChildren: () => import('./doctor/advertisement/bankaccountedit/bankaccountedit.module').then( m => m.BankaccounteditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'bankaccountview',
    loadChildren: () => import('./doctor/advertisement/bankaccountview/bankaccountview.module').then( m => m.BankaccountviewPageModule)
    ,canLoad: [CheckTutorial]
  },


  {
    path: 'prescriptiontemplate',
    loadChildren: () => import('./doctor/advertisement/prescriptiontemplate/prescriptiontemplate.module').then( m => m.PrescriptiontemplatePageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'prescriptiontemplateadd',
    loadChildren: () => import('./doctor/advertisement/prescriptiontemplateadd/prescriptiontemplateadd.module').then( m => m.PrescriptiontemplateaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'prescriptiontemplateedit/:id',
    loadChildren: () => import('./doctor/advertisement/prescriptiontemplateedit/prescriptiontemplateedit.module').then( m => m.PrescriptiontemplateeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'prescriptiontemplatedelete/:id',
    loadChildren: () => import('./doctor/advertisement/prescriptiontemplatedelete/prescriptiontemplatedelete.module').then( m => m.PrescriptiontemplatedeletePageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'waitingroom/:id',
    loadChildren: () => import('./doctor/advertisement/waitingroom/waitingroom.module').then( m => m.WaitingroomPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'clinic/:id/:cid/:hid',
    loadChildren: () => import('./doctor/advertisement/clinic/clinic.module').then( m => m.ClinicPageModule)
    ,canLoad: [CheckTutorial]
  },




  
  {
    path: 'drhospital',
    loadChildren: () => import('./doctor/advertisement/hospital/hospital.module').then( m => m.HospitalPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'drhospitaledit/:id',
    loadChildren: () => import('./doctor/advertisement/hospitaledit/hospitaledit.module').then( m => m.HospitaleditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'drhospitaldelete/:id',
    loadChildren: () => import('./doctor/advertisement/hospitaldelete/hospitaldelete.module').then( m => m.HospitaldeletePageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'pdfviewer/:id/:viid/:uid',
    loadChildren: () => import('./viewer/common/pdfviewer/pdfviewer.module').then( m => m.PdfviewerPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'docviewer/:id/:viid/:uid',
    loadChildren: () => import('./viewer/common/docviewer/docviewer.module').then( m => m.DocviewerPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'picviewer/:id/:viid/:uid',
    loadChildren: () => import('./viewer/common/picviewer/picviewer.module').then( m => m.PicviewerPageModule)
    ,canLoad: [CheckTutorial]
  },




  {
    path: 'hosp-billingadd',
    loadChildren: () => import('./hospital/billing/hosp-billingadd/hosp-billingadd.module').then( m => m.HospBillingaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-billingview/:id',
    loadChildren: () => import('./hospital/billing/hosp-billingview/hosp-billingview.module').then( m => m.HospBillingviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-billingedit/:id',
    loadChildren: () => import('./hospital/billing/hosp-billingedit/hosp-billingedit.module').then( m => m.HospBillingeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-billinglist',
    loadChildren: () => import('./hospital/billing/hosp-billinglist/hosp-billinglist.module').then( m => m.HospBillinglistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-prescriptionadd',
    loadChildren: () => import('./hospital/prescription/hosp-prescriptionadd/hosp-prescriptionadd.module').then( m => m.HospPrescriptionaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-prescriptionview/:id',
    loadChildren: () => import('./hospital/prescription/hosp-prescriptionview/hosp-prescriptionview.module').then( m => m.HospPrescriptionviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'drprescriptionview/:id/:uid',
    loadChildren: () => import('./doctor/advertisement/hosp-prescriptionview/hosp-prescriptionview.module').then( m => m.HospPrescriptionviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'hosp-prescriptionedit/:id',
    loadChildren: () => import('./hospital/prescription/hosp-prescriptionedit/hosp-prescriptionedit.module').then( m => m.HospPrescriptioneditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-prescriptionlist',
    loadChildren: () => import('./hospital/prescription/hosp-prescriptionlist/hosp-prescriptionlist.module').then( m => m.HospPrescriptionlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-patientadd',
    loadChildren: () => import('./hospital/patient/hosp-patientadd/hosp-patientadd.module').then( m => m.HospPatientaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-patientview/:id',
    loadChildren: () => import('./hospital/patient/hosp-patientview/hosp-patientview.module').then( m => m.HospPatientviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-patientedit/:id',
    loadChildren: () => import('./hospital/patient/hosp-patientedit/hosp-patientedit.module').then( m => m.HospPatienteditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-patientlist',
    loadChildren: () => import('./hospital/patient/hosp-patientlist/hosp-patientlist.module').then( m => m.HospPatientlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-doctorsadd',
    loadChildren: () => import('./hospital/doctors/hosp-doctorsadd/hosp-doctorsadd.module').then( m => m.HospDoctorsaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-doctorsview/:id',
    loadChildren: () => import('./hospital/doctors/hosp-doctorsview/hosp-doctorsview.module').then( m => m.HospDoctorsviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-doctorsedit/:id',
    loadChildren: () => import('./hospital/doctors/hosp-doctorsedit/hosp-doctorsedit.module').then( m => m.HospDoctorseditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-doctorslist',
    loadChildren: () => import('./hospital/doctors/hosp-doctorslist/hosp-doctorslist.module').then( m => m.HospDoctorslistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-hospitaladd',
    loadChildren: () => import('./hospital/hospital/hosp-hospitaladd/hosp-hospitaladd.module').then( m => m.HospHospitaladdPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-hospitalview/:id',
    loadChildren: () => import('./hospital/hospital/hosp-hospitalview/hosp-hospitalview.module').then( m => m.HospHospitalviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-hospitaledit/:id',
    loadChildren: () => import('./hospital/hospital/hosp-hospitaledit/hosp-hospitaledit.module').then( m => m.HospHospitaleditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-hospitallist',
    loadChildren: () => import('./hospital/hospital/hosp-hospitallist/hosp-hospitallist.module').then( m => m.HospHospitallistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-pharmacyadd',
    loadChildren: () => import('./hospital/pharmacy/hosp-pharmacyadd/hosp-pharmacyadd.module').then( m => m.HospPharmacyaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-pharmacyview/:id',
    loadChildren: () => import('./hospital/pharmacy/hosp-pharmacyview/hosp-pharmacyview.module').then( m => m.HospPharmacyviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-pharmacyedit/:id',
    loadChildren: () => import('./hospital/pharmacy/hosp-pharmacyedit/hosp-pharmacyedit.module').then( m => m.HospPharmacyeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-pharmacylist',
    loadChildren: () => import('./hospital/pharmacy/hosp-pharmacylist/hosp-pharmacylist.module').then( m => m.HospPharmacylistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-laboratoryadd',
    loadChildren: () => import('./hospital/laboratory/hosp-laboratoryadd/hosp-laboratoryadd.module').then( m => m.HospLaboratoryaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-laboratoryview/:id',
    loadChildren: () => import('./hospital/laboratory/hosp-laboratoryview/hosp-laboratoryview.module').then( m => m.HospLaboratoryviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-laboratoryedit/:id',
    loadChildren: () => import('./hospital/laboratory/hosp-laboratoryedit/hosp-laboratoryedit.module').then( m => m.HospLaboratoryeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-laboratorylist',
    loadChildren: () => import('./hospital/laboratory/hosp-laboratorylist/hosp-laboratorylist.module').then( m => m.HospLaboratorylistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementadd',
    loadChildren: () => import('./hospital/advertisement/hosp-advertisementadd/hosp-advertisementadd.module').then( m => m.HospAdvertisementaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementview/:id',
    loadChildren: () => import('./hospital/advertisement/hosp-advertisementview/hosp-advertisementview.module').then( m => m.HospAdvertisementviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementedit/:id',
    loadChildren: () => import('./hospital/advertisement/hosp-advertisementedit/hosp-advertisementedit.module').then( m => m.HospAdvertisementeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementlist',
    loadChildren: () => import('./hospital/advertisement/hosp-advertisementlist/hosp-advertisementlist.module').then( m => m.HospAdvertisementlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementsearchlist',
    loadChildren: () => import('./hospital/advertisement/hosp-advertisementsearchlist/hosp-advertisementsearchlist.module').then( m => m.HospAdvertisementsearchlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementpublishedview/:id',
    loadChildren: () => import('./hospital/advertisement/hospadvertisementpublishedview/hospadvertisementpublishedview.module').then( m => m.HospadvertisementpublishedviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementbillview/:id',
    loadChildren: () => import('./hospital/advertisement/hospadvertisementbillview/hospadvertisementbillview.module').then( m => m.HospadvertisementbillviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-advertisementpay/:id',
    loadChildren: () => import('./hospital/advertisement/hosp-advertisementpay/hosp-advertisementpay.module').then( m => m.HospAdvertisementpayPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-laboratorypay/:id',
    loadChildren: () => import('./hospital/laboratory/hosp-laboratorypay/hosp-laboratorypay.module').then( m => m.HospLaboratorypayPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-pharmacypay/:id',
    loadChildren: () => import('./hospital/pharmacy/hosp-pharmacypay/hosp-pharmacypay.module').then( m => m.HospPharmacypayPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'hosp-hospitalpay/:id',
    loadChildren: () => import('./hospital/hospital/hosp-hospitalpay/hosp-hospitalpay.module').then( m => m.HospHospitalpayPageModule)
    ,canLoad: [CheckTutorial]
  },












  
  {
    path: 'admindepartmentadd',
    loadChildren: () => import('./admin/department/departmentadd/departmentadd.module').then( m => m.DepartmentaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'admindepartmentview/:id',
    loadChildren: () => import('./admin/department/departmentview/departmentview.module').then( m => m.DepartmentviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'admindepartmentedit/:id',
    loadChildren: () => import('./admin/department/departmentedit/departmentedit.module').then( m => m.DepartmenteditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'admindepartmentlist',
    loadChildren: () => import('./admin/department/departmentlist/departmentlist.module').then( m => m.DepartmentlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'subscriptionadd',
    loadChildren: () => import('./admin/subscription/subscriptionadd/subscriptionadd.module').then( m => m.SubscriptionaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'subscriptionview/:id',
    loadChildren: () => import('./admin/subscription/subscriptionview/subscriptionview.module').then( m => m.SubscriptionviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'subscriptionedit/:id',
    loadChildren: () => import('./admin/subscription/subscriptionedit/subscriptionedit.module').then( m => m.SubscriptioneditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'subscriptionlist',
    loadChildren: () => import('./admin/subscription/subscriptionlist/subscriptionlist.module').then( m => m.SubscriptionlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pricemasteradd',
    loadChildren: () => import('./admin/pricemaster/pricemasteradd/pricemasteradd.module').then( m => m.PricemasteraddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pricemasterview/:id',
    loadChildren: () => import('./admin/pricemaster/pricemasterview/pricemasterview.module').then( m => m.PricemasterviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pricemasteredit/:id',
    loadChildren: () => import('./admin/pricemaster/pricemasteredit/pricemasteredit.module').then( m => m.PricemastereditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'pricemasterlist',
    loadChildren: () => import('./admin/pricemaster/pricemasterlist/pricemasterlist.module').then( m => m.PricemasterlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adspriceadd',
    loadChildren: () => import('./admin/adsprice/adspriceadd/adspriceadd.module').then( m => m.AdspriceaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adspriceview/:id',
    loadChildren: () => import('./admin/adsprice/adspriceview/adspriceview.module').then( m => m.AdspriceviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adspriceedit/:id',
    loadChildren: () => import('./admin/adsprice/adspriceedit/adspriceedit.module').then( m => m.AdspriceeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adspricelist',
    loadChildren: () => import('./admin/adsprice/adspricelist/adspricelist.module').then( m => m.AdspricelistPageModule)
    ,canLoad: [CheckTutorial]
  },
























  
  
  {
    path: 'rep-doctorsadd/:id',
    loadChildren: () => import('./representative/doctors/hosp-doctorsadd/hosp-doctorsadd.module').then( m => m.HospDoctorsaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-doctorsview/:id',
    loadChildren: () => import('./representative/doctors/hosp-doctorsview/hosp-doctorsview.module').then( m => m.HospDoctorsviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-doctorsedit/:id',
    loadChildren: () => import('./representative/doctors/hosp-doctorsedit/hosp-doctorsedit.module').then( m => m.HospDoctorseditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-doctorslist',
    loadChildren: () => import('./representative/doctors/hosp-doctorslist/hosp-doctorslist.module').then( m => m.HospDoctorslistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-hospitaladd/:id',
    loadChildren: () => import('./representative/hospital/hosp-hospitaladd/hosp-hospitaladd.module').then( m => m.HospHospitaladdPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-hospitalview/:id',
    loadChildren: () => import('./representative/hospital/hosp-hospitalview/hosp-hospitalview.module').then( m => m.HospHospitalviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-hospitaledit/:id',
    loadChildren: () => import('./representative/hospital/hosp-hospitaledit/hosp-hospitaledit.module').then( m => m.HospHospitaleditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-hospitalpay/:id',
    loadChildren: () => import('./representative/hospital/hosp-hospitalpayment/hosp-hospitalpayment.module').then( m => m.HospHospitalpaymentPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-hospitallist',
    loadChildren: () => import('./representative/hospital/hosp-hospitallist/hosp-hospitallist.module').then( m => m.HospHospitallistPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'hosphospitalearnings',
    loadChildren: () => import('./representative/hospital/hosp-hospital-earnings/hosp-hospital-earnings.module').then( m => m.HospHospitalEarningsPageModule)
    ,canLoad: [CheckTutorial]
  },


  {
    path: 'advertisementearnings',
    loadChildren: () => import('./representative/advertisement/hosp-hospital-earnings/hosp-hospital-earnings.module').then( m => m.HospHospitalEarningsPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'laboratoryearnings',
    loadChildren: () => import('./representative/laboratory/hosp-hospital-earnings/hosp-hospital-earnings.module').then( m => m.HospHospitalEarningsPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'pharmacyearnings',
    loadChildren: () => import('./representative/pharmacy/hosp-hospital-earnings/hosp-hospital-earnings.module').then( m => m.HospHospitalEarningsPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'consultationearnings',
    loadChildren: () => import('./representative/patientvisit/hosp-hospital-earnings/hosp-hospital-earnings.module').then( m => m.HospHospitalEarningsPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-pharmacyadd/:id',
    loadChildren: () => import('./representative/pharmacy/hosp-pharmacyadd/hosp-pharmacyadd.module').then( m => m.HospPharmacyaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-pharmacyview/:id',
    loadChildren: () => import('./representative/pharmacy/hosp-pharmacyview/hosp-pharmacyview.module').then( m => m.HospPharmacyviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-pharmacyedit/:id',
    loadChildren: () => import('./representative/pharmacy/hosp-pharmacyedit/hosp-pharmacyedit.module').then( m => m.HospPharmacyeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-pharmacylist',
    loadChildren: () => import('./representative/pharmacy/hosp-pharmacylist/hosp-pharmacylist.module').then( m => m.HospPharmacylistPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-pharmacypay/:id',
    loadChildren: () => import('./representative/pharmacy/hosp-pharmacypay/hosp-pharmacypay.module').then( m => m.HospPharmacypayPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-laboratoryadd/:id',
    loadChildren: () => import('./representative/laboratory/hosp-laboratoryadd/hosp-laboratoryadd.module').then( m => m.HospLaboratoryaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-laboratoryview/:id',
    loadChildren: () => import('./representative/laboratory/hosp-laboratoryview/hosp-laboratoryview.module').then( m => m.HospLaboratoryviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-laboratoryedit/:id',
    loadChildren: () => import('./representative/laboratory/hosp-laboratoryedit/hosp-laboratoryedit.module').then( m => m.HospLaboratoryeditPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-laboratorypay/:id',
    loadChildren: () => import('./representative/laboratory/hosp-laboratorypay/hosp-laboratorypay.module').then( m => m.HospLaboratorypayPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-laboratorylist',
    loadChildren: () => import('./representative/laboratory/hosp-laboratorylist/hosp-laboratorylist.module').then( m => m.HospLaboratorylistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-advertisementadd',
    loadChildren: () => import('./representative/advertisement/hosp-advertisementadd/hosp-advertisementadd.module').then( m => m.HospAdvertisementaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-advertisementview/:id',
    loadChildren: () => import('./representative/advertisement/hosp-advertisementview/hosp-advertisementview.module').then( m => m.HospAdvertisementviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-advertisementedit/:id',
    loadChildren: () => import('./representative/advertisement/hosp-advertisementedit/hosp-advertisementedit.module').then( m => m.HospAdvertisementeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'rep-advertisementlist',
    loadChildren: () => import('./representative/advertisement/hosp-advertisementlist/hosp-advertisementlist.module').then( m => m.HospAdvertisementlistPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-advertisementsearchlist',
    loadChildren: () => import('./representative/advertisement/hosp-advertisementsearchlist/hosp-advertisementsearchlist.module').then( m => m.HospAdvertisementsearchlistPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-hospadvertisementbillview/:id',
    loadChildren: () => import('./representative/advertisement/hospadvertisementbillview/hospadvertisementbillview.module').then( m => m.HospadvertisementbillviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'rep-hospadvertisementpublishedview/:id',
    loadChildren: () => import('./representative/advertisement/hospadvertisementpublishedview/hospadvertisementpublishedview.module').then( m => m.HospadvertisementpublishedviewPageModule)
    ,canLoad: [CheckTutorial]
  },










  {
    path: 'adddoctorsadd',
    loadChildren: () => import('./representative/adddoctors/adddoctorsadd/adddoctorsadd.module').then( m => m.AdddoctorsaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adddoctorslist',
    loadChildren: () => import('./representative/adddoctors/adddoctorslist/adddoctorslist.module').then( m => m.AdddoctorslistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'addinstitutionadd',
    loadChildren: () => import('./representative/addinstitution/addinstitutionadd/addinstitutionadd.module').then( m => m.AddinstitutionaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'addinstitutionlist',
    loadChildren: () => import('./representative/addinstitution/addinstitutionlist/addinstitutionlist.module').then( m => m.AddinstitutionlistPageModule)
    ,canLoad: [CheckTutorial]
  },















  
  {
    path: 'callamount',
    loadChildren: () => import('./accounts/receivedcall/callamount/callamount.module').then( m => m.CallamountPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'softamount',
    loadChildren: () => import('./accounts/receivedsoft/softamount/softamount.module').then( m => m.SoftamountPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adsamount',
    loadChildren: () => import('./accounts/receivedads/adsamount/adsamount.module').then( m => m.AdsamountPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'calltransaction',
    loadChildren: () => import('./accounts/receivedcall/calltransaction/calltransaction.module').then( m => m.CalltransactionPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'softtransaction',
    loadChildren: () => import('./accounts/receivedsoft/softtransaction/softtransaction.module').then( m => m.SofttransactionPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'adstransaction',
    loadChildren: () => import('./accounts/receivedads/adstransaction/adstransaction.module').then( m => m.AdstransactionPageModule)
    ,canLoad: [CheckTutorial]
  },









  {
    path: 'advertisementadd',
    loadChildren: () => import('./pages/patient/advertisement/advertisementadd/advertisementadd.module').then( m => m.AdvertisementaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'advertisementlist',
    loadChildren: () => import('./pages/patient/advertisement/advertisementlist/advertisementlist.module').then( m => m.AdvertisementlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'advertisementview',
    loadChildren: () => import('./pages/patient/advertisement/advertisementview/advertisementview.module').then( m => m.AdvertisementviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'advertisementedit/:id',
    loadChildren: () => import('./pages/patient/advertisement/advertisementedit/advertisementedit.module').then( m => m.AdvertisementeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'advertisementpublishededit/:id',
    loadChildren: () => import('./pages/patient/advertisement/advertisementpublishededit/advertisementpublishededit.module').then( m => m.AdvertisementpublishededitPageModule)
    ,canLoad: [CheckTutorial]
 },
  {
    path: 'advertisementpublishedlist',
    loadChildren: () => import('./pages/patient/advertisement/advertisementpublishedlist/advertisementpublishedlist.module').then( m => m.AdvertisementpublishedlistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'advertisementpublishedview/:id',
    loadChildren: () => import('./pages/patient/advertisement/advertisementpublishedview/advertisementpublishedview.module').then( m => m.AdvertisementpublishedviewPageModule)
    ,canLoad: [CheckTutorial]
  },

  {
    path: 'advertisementbillview/:id',
    loadChildren: () => import('./pages/patient/advertisement/advertisementbillview/advertisementbillview.module').then( m => m.AdvertisementbillviewPageModule)
    ,canLoad: [CheckTutorial]
  },



  {
    path: 'templateadd',
    loadChildren: () => import('./admin/template/templateadd/templateadd.module').then( m => m.TemplateaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'templateview/:id',
    loadChildren: () => import('./admin/template/templateview/templateview.module').then( m => m.TemplateviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'templateedit/:id',
    loadChildren: () => import('./admin/template/templateedit/templateedit.module').then( m => m.TemplateeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'templatelist',
    loadChildren: () => import('./admin/template/templatelist/templatelist.module').then( m => m.TemplatelistPageModule)
    ,canLoad: [CheckTutorial]
  },






  {
    path: 'settingsadd',
    loadChildren: () => import('./admin/settings/templateadd/templateadd.module').then( m => m.TemplateaddPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'settingsview/:id',
    loadChildren: () => import('./admin/settings/templateview/templateview.module').then( m => m.TemplateviewPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'settingsedit/:id',
    loadChildren: () => import('./admin/settings/templateedit/templateedit.module').then( m => m.TemplateeditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'settingslist',
    loadChildren: () => import('./admin/settings/templatelist/templatelist.module').then( m => m.TemplatelistPageModule)
    ,canLoad: [CheckTutorial]
  },



  
  {
    path: 'chatbotedit/:id',
    loadChildren: () => import('./admin/chatbot/hosp-hospitaledit/hosp-hospitaledit.module').then( m => m.HospHospitaleditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'chatbotlist',
    loadChildren: () => import('./admin/chatbot/hosp-hospitallist/hosp-hospitallist.module').then( m => m.HospHospitallistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'chatbotadd',
    loadChildren: () => import('./admin/chatbot/hosp-hospitaladd/hosp-hospitaladd.module').then( m => m.HospHospitaladdPageModule)
    ,canLoad: [CheckTutorial]
  },


  
  {
    path: 'helpedit/:id',
    loadChildren: () => import('./admin/help/hosp-hospitaledit/hosp-hospitaledit.module').then( m => m.HospHospitaleditPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'helplist',
    loadChildren: () => import('./admin/help/hosp-hospitallist/hosp-hospitallist.module').then( m => m.HospHospitallistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'helpadd',
    loadChildren: () => import('./admin/help/hosp-hospitaladd/hosp-hospitaladd.module').then( m => m.HospHospitaladdPageModule)
    ,canLoad: [CheckTutorial]
  },







  {
    path: 'notifications',
    loadChildren: () => import('./notifications/hosp-hospitallist/hosp-hospitallist.module').then( m => m.HospHospitallistPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'notificationsview/:id',
    loadChildren: () => import('./notifications/hosp-hospitalview/hosp-hospitalview.module').then( m => m.HospHospitalviewPageModule)
    ,canLoad: [CheckTutorial]
  },









  {
    path: 'settings',
    loadChildren: () => import('./hospital/settings/settings/settings.module').then( m => m.SettingsPageModule)
    ,canLoad: [CheckTutorial]
  },


  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}

import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';


import { RouteReuseStrategy } from '@angular/router';
import {  IonicRouteStrategy } from '@ionic/angular';

import {  ReactiveFormsModule } from '@angular/forms';

import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { MaterialIconsModule } from 'ionic2-material-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { FaIconLibrary,FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { IonicKeyboardAssist } from 'ionic-keyboard-assist';
//import { CKEditorModule } from 'ng2-ckeditor';
import { IonicPageModule } from 'ionic-angular';
import  { HospitaladdPage } from './pages/hospital/hospitaladd/hospitaladd.page';
import * as jquery from 'jquery';
import { IonicSelectableModule } from 'ionic-selectable';
import { PortService } from './services';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { Pdf417BarcodeModule } from "pdf417-barcode";

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { File, DirectoryEntry, FileEntry } from '@ionic-native/file';
import { Base64 } from '@ionic-native/base64';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { JoditAngularModule } from 'jodit-angular';
import { QuillModule } from 'ngx-quill';
import { SwiperModule } from 'swiper/angular';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxLoadingModule } from 'ngx-loading';

import { Chooser, ChooserResult } from '@awesome-cordova-plugins/chooser/ngx';
//import { Chooser, ChooserResult } from '@ionic-native/chooser/ngx';
import { DxHtmlEditorModule, DxCheckBoxModule } from 'devextreme-angular';
import { NativeRingtones } from '@ionic-native/native-ringtones';
import { DatePipe } from '@angular/common';



@NgModule({
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist: false
    }),
    IonicModule.forRoot(),
    QuillModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    IonicSelectableModule,
    AppRoutingModule,
    CKEditorModule,
    SwiperModule,
    HttpClientModule,
    NgxBarcode6Module,
    Pdf417BarcodeModule,
    FormsModule,
    DxHtmlEditorModule, DxCheckBoxModule,
    JoditAngularModule,
    NgxIonicImageViewerModule,
    FontAwesomeModule, 
    ReactiveFormsModule     ,
    BrowserAnimationsModule,
    MaterialIconsModule ,
    IonIntlTelInputModule,
    NgxIntlTelInputModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent],
  providers: [ PortService, AndroidPermissions, QRScanner,
     PDFGenerator,BarcodeScanner, InAppBrowser, IonicKeyboardAssist,
      SplashScreen, StatusBar,AndroidFullScreen,  ErrorHandler,
      Chooser, DatePipe,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}

<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">


      <ion-header>
        <ion-toolbar color="dark" class="user-profile">

          <ion-item style="--ion-item-background: transparent;" margin-bottom>
            <ion-avatar slot="start" class="user-avatar">
              <img [src]="ProfilePic">
            </ion-avatar>
            <ion-label>
              <ion-text color="medium">
                <h3><strong>{{UserName}}</strong></h3>
              </ion-text>
              <ion-text color="secondary">
                <h3>
                  {{internationalNumber}}
                </h3>
              </ion-text>
              <ion-menu-toggle class="mto" auto-hide="false">

              </ion-menu-toggle>
            </ion-label>
          </ion-item>

        </ion-toolbar>
      </ion-header>


      
      <ion-content id="my-content1" >
        <ion-list lines="none">
          <ion-list-header>
            {{Clinic}} 
          </ion-list-header>
          <ion-menu-toggle    autoHide="false" *ngFor="let p of appPages; let i = index">
            <div [hidden]="p.show" >
            <ion-item [routerLink]="p.url"  (click)="toggle(p)" routerLinkActive="selected" routerDirection="root"  detail="false">
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
              <div [hidden]="!p.hasChild" >
              <ion-icon slot="end" [hidden]="!p.expanded" name="chevron-up-outline" ></ion-icon>
              <ion-icon slot="end" [hidden]="p.expanded" name="chevron-down-outline" ></ion-icon>
            </div>
            </ion-item>
          </div>
            <ion-list [hidden]="!p.expanded"  >
              <ion-menu-toggle  autoHide="false" *ngFor="let s of p.subPages"    >
                <ion-item class="bottomRow" [routerDirection]="'root'" [routerLink]="[s.url]" routerLinkActive="selected"  detail="false" >
                  <ion-icon slot="start" [name]="s.icon + '-outline'"></ion-icon>
                  <ion-label>
                    {{s.title}}
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>

          </ion-menu-toggle>
        </ion-list>


        <ion-list  lines="none">
          <ion-list-header>
            {{Account}}
          </ion-list-header>


          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                Logout
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Support
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/signup" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person-circle"></ion-icon>
              <ion-label>
                Profile
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item>
            <ion-icon slot="start" name="moon-outline"></ion-icon>
            <ion-label>
              Dark Mode
            </ion-label>
            <ion-toggle [(ngModel)]="dark"></ion-toggle>
          </ion-item>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            {{Help}}
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="openTutorial()" detail="false">
              <ion-icon slot="start" name="hammer"></ion-icon>
              <ion-label>Show Help</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>

     
    </ion-menu>



    

    <ion-router-outlet id="main-content">



    </ion-router-outlet>


    
  </ion-split-pane>



</ion-app>





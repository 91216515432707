import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import{ GlobalConstants } from './common/globalconstants';
import { Storage } from '@ionic/storage';
import { AlertController } from '@ionic/angular';
import { UserData } from './providers/user-data';
import { AndroidFullScreen , AndroidSystemUiFlags } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { IonicKeyboardAssist } from 'ionic-keyboard-assist';
import * as jquery from 'jquery';
//import { LocalNotifications } from '@capacitor/local-notifications';
//import { StatusBar, Style } from '@capacitor/status-bar';

//import { NavController } from 'ionic-angular';
import { DashboardPage } from './pages/patient/dashboard/dashboard.page';     //'./ /dashboard.page';
import { Plugins } from '@capacitor/core';
const { LocalNotifications } = Plugins;
import { DatePipe } from '@angular/common';



declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
 
  appPages = [];
  loggedIn = false;
  dark = false;
  apiURL = GlobalConstants.apiURL;
 


  Clinic : string = "Clinic" ;
  Account : string = "Account" ;
  Help : string = "Help" ;

  checksoftwareandMainmenu()
  {
    if(this.currentsoftware == 1)
    {
      this.Clinic  = "Clinic" ;
      this.Account  = "Account" ;
      this.Help  = "Help" ;
    }
    if(this.currentsoftware == 2)
    {
      this.Clinic  = "Clinic" ;
      this.Account  = "Account" ;
      this.Help  = "Help" ;
    }
    if(this.currentsoftware == 3)
    {
      this.Clinic  = "Clinic" ;
      this.Account  = "Account" ;
      this.Help  = "Help" ;
    }
    if(this.currentsoftware == 4)
    {
      this.Clinic  = "Clinic" ;
      this.Account  = "Account" ;
      this.Help  = "Help" ;
    }
    if(this.currentsoftware == 5)
    {
      this.Clinic  = "Clinic" ;
      this.Account  = "Account" ;
      this.Help  = "Help" ;
    }
    if(this.currentsoftware == 6)
    {
      this.Clinic  = "Clinic" ;
      this.Account  = "Account" ;
      this.Help  = "Help" ;
    }
  }

  clock="";
  clockHandle;
  counter : number = 0;
  startTimer() {   
    this.clockHandle = setInterval(()=>{
      this.clock = new Date().toLocaleTimeString();
      this.counter = this.counter + 1;

    //  GlobalConstants.notificationcount = this.counter;

   ///   this.navCtrl.push(DashboardPage, {
    //    appid: this.counter ,
   //    appname: "Carl"
   //   });
      if(this.counter > 40)
      {
        this.counter = 0;
        this.callnotification();
        this.callnotificationActive();
       // this.LoadPatientnVisitgOnInit();
      }
    },1000);
    }



    notificationid : string;
    notificationuid : string;
    softtypee : string;
    created : string;

    viewed : string;
    Smallicon : string;
    Appname : string;
    Timestamp : string;

    Largeicon : string;
    Title : string;
    Text : string;
    largeBody : string;

    summaryText : string;
    schedule : string;
    sound : string;
    iconColor : string;

    sended : string;
    viewedDate : string;

callnotification(){
  try{
//  alert( this.edprid + '   -    ' + this.softtype );
  this.http.post( this.apiURL + 'notification.php?Control=selectnotificationid', {
    SelectDepartment: "SelectpostalcodeID",
    uid:  this.edprid ,
    softtype: this.softtype,
    Control: "selectnotificationid"
  })
  .subscribe(
    res => {


      let kkk = JSON.stringify(res);
      var obj = JSON.parse(kkk);
   
      for(var i = 0; i < obj.length; i++) {

    //    alert( obj[i].Title + '   -    ' + obj[i].Text );
        this.notificationid = obj[i].id;
        this.notificationuid = obj[i].uid;
        this.softtypee = obj[i].softtype;
        this.created = obj[i].created;

        this.viewed = obj[i].viewed;
        this.Smallicon = obj[i].Smallicon;
        this.Appname = obj[i].Appname;
        this.Timestamp = obj[i].Timestamp;

        this.Largeicon = obj[i].Largeicon;
        this.Title = obj[i].Title;
        this.Text = obj[i].Text;
        this.largeBody = obj[i].largeBody;

        this.summaryText = obj[i].summaryText;
        this.schedule = obj[i].schedule;
        this.sound = obj[i].sound;
        this.iconColor = obj[i].iconColor;

        this.sended = obj[i].sended;
        this.viewedDate = obj[i].viewedDate;
        GlobalConstants.notificationcount = obj[i].total;

        this.Notification();
       }

       
    },
    err => {
      console.log(err);
      console.log('Error occured');
     // alert(err.text);
    }
  ); 
  }catch{}
}





callnotificationActive(){
  try{
    this.http.post( this.apiURL + 'notification.php?Control=selectnotificationidActive', {
      SelectDepartment: "selectnotificationidActive",
      uid:  this.edprid ,
      softtype: this.softtype,
      Control: "selectnotificationidActive"
    })
    .subscribe(
      res => {
  
  
        let kkk = JSON.stringify(res);
        var obj = JSON.parse(kkk);
     
        for(var i = 0; i < obj.length; i++) {
  
      //    alert( obj[i].Title + '   -    ' + obj[i].Text );
          this.notificationid = obj[i].id;
      
         }
  
         
      },
      err => {
        console.log(err);
        console.log('Error occured');
       // alert(err.text);
      }
    ); 
  }catch{}
  }
  













async Notification(){
  const notifs = await LocalNotifications.schedule({
    notifications: [
      {
        title: this.Title ,
        body: this.Text,
        id: 1,
        schedule: { at: new Date(Date.now() + 10 * 5) },
        sound: null,
        actionTypeId: '',
        extra: null,
        smallIcon:   this.Smallicon,
        largeIcon: this.Largeicon,
        iconColor : "#488AFF",
        attachments: [{url: 'res:///assets/img/hospital.png'}]
      },
    ],
  });
  console.log('scheduled notifications', notifs);

}







  constructor(
    private http: HttpClient, 
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
   private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private androidFullScreen: AndroidFullScreen,
    public alertController: AlertController,
 //   public navCtrl: NavController,
    private keyboardAssist: IonicKeyboardAssist ,
    public datepipe: DatePipe
    
  ) {

this.startTimer();
 //   const setStatusBarStyleLight = async () => {
  //    await StatusBar.setStyle({ style: Style.Light });
  //  };
    

  //  LocalNotifications.schedule({
 //     notifications: [
  //      {
  //        title: "On sale",
  //        body: "Widgets are 10% off. Act fast!",
   ////       id: 1,
   //       schedule: { at: new Date(Date.now() + 1 * 4) },
   //       sound: null,
   //       attachments: null,
   //       actionTypeId: "",
   //       extra: null
   //     }
   //   ]
  //  });

this.checksoftwareandMainmenu();

    if(this.currentsoftware == 3)
    {   try{
        this.hospitalsettings();
        }catch{}
    }
    this. checksoftwareandUser();
    this.keyboardAssist.init();

  this.initializeApp();

    this.androidFullScreen.isImmersiveModeSupported()
    .then(() => 
        this.androidFullScreen.setSystemUiVisibility(AndroidSystemUiFlags.Fullscreen)
    //  this.androidFullScreen.immersiveMode()
      //this.androidFullScreen.immersiveHeight()
      //this.androidFullScreen.immersiveWidth()
      //this.androidFullScreen.leanMode()

      //this.androidFullScreen.showUnderSystemUI()
      //this.androidFullScreen.showUnderStatusBar()
      //this.androidFullScreen.showSystemUI()
    )
    .catch(err => console.log(err));




    this.loadapplicationsettings();
    this.loadhitcount();


  }

  edprid: string;
  ProfilePic: string;
  UserName: string;
  internationalNumber: string;
  softtype: string;
  checksoftwareandUser()
  {
    if(this.currentsoftware == 1)
    {
        if (localStorage.getItem('user')) { 
            var res = localStorage.getItem('user');
            var obj = JSON.parse(res);
            this.softtype = 'Patient';
            this.edprid =  obj.id;
            this.edprid =  obj.id;
            this.ProfilePic =  obj.ProfilePic;
            if(this.ProfilePic == "" || this.ProfilePic == null)
            {
              this.ProfilePic= "assets/images/profile/avatar1.png";
            }
            this.UserName =  obj.UserName;
            if(this.UserName == "" || this.UserName == null)
            {
              this.UserName = "User Name";
            }
            this.internationalNumber =  obj.internationalNumber;
            //alert(this.internationalNumber);


            this.appPages = [
              {
                title: 'Dashboard',
                url: '/app/tabs/dashboard',
                icon: 'flower'
              },
              
              {
                title: 'Family',
                url: '/app/tabs/list',
                icon: 'people'
              },
          
              {
                title: 'Doctor',
                url: '/app/tabs/doctorsearchlist/0/0/0',
                icon: 'ribbon'
              },
          
              {
                title: 'Hospitals',
                url: '/app/tabs/hospitallist',
                icon: 'business'
              },
          
              {
                title: 'Speciality',
                url: '/app/tabs/departmentlist',
                icon: 'fitness'
              },
          
              {
                title: 'Schedule',
                url: '/app/tabs/patschedule',
                icon: 'calendar'
              },
          
              {
                title: 'Pharmacy',
                url: '/app/tabs/pharmacylist',
                icon: 'bandage'
              },
          
              {
                title: 'Laboratory',
                url: '/app/tabs/laboratorylist',
                icon: 'flask'
              }

              ,
          
              {
                title: 'Advertisement',
                url: '/app/tabs/advertisementlist',
                icon: 'ribbon'
              }
          
            ];

        }else{
            this.router.navigate(['/signin']);
        }
    }

    if(this.currentsoftware == 2)
    {
              if (localStorage.getItem('Doctoruser')) { 
                var res = localStorage.getItem('Doctoruser');
                var obj = JSON.parse(res);
                this.edprid =  obj.id;
                this.edprid =  obj.id;
                this.softtype = 'Doctor';
                this.ProfilePic =  obj.ProfilePic;
                if(this.ProfilePic == "" || this.ProfilePic == null)
                {
                  this.ProfilePic= "assets/images/profile/avatar1.png";
                }
                this.UserName =  obj.UserName;
                if(this.UserName == "" || this.UserName == null)
                {
                  this.UserName = "User Name";
                }
                this.internationalNumber =  obj.internationalNumber;
                //alert(this.internationalNumber);

                this.appPages = [
                  {
                    title: 'Dashboard',
                    url: '/app/tabs/doctordashboard',
                    icon: 'flower'
                  },
                  {
                    title: 'Clinic',
                    url: '/app/tabs/waitingroom/' + this.edprid ,
                    icon: 'apps'
                  },

                  {
                    title: 'Hospital',
                    url: '/app/tabs/drhospital' ,
                    icon: 'business'
                  },

                  {
                    title: 'Profile',
                    url: '/app/tabs/doctorview/' + this.edprid ,
                    icon: 'ribbon'
                  },
                  {
                    title: 'Documents',
                    url: '/app/tabs/medicalcouncilview' ,
                    icon: 'receipt'
                  },
                  {
                    title: 'Bank Account',
                    url: '/app/tabs/bankaccountview' ,
                    icon: 'cash'
                  },

                  {
                    title: 'Pres. Template',
                    url: '/app/tabs/prescriptiontemplate' ,
                    icon: 'clipboard'
                  }
                  ,
          
                  {
                    title: 'Advertisement',
                    url: '/app/tabs/advertisementlist',
                    icon: 'ribbon'
                  }
              
                ];
            }else{
                this.router.navigate(['/signin']);
            }
    }

    if(this.currentsoftware == 3)
    {
            if (localStorage.getItem('Hospitaluser')) { 
              var res = localStorage.getItem('Hospitaluser');
              var obj = JSON.parse(res);
              this.edprid =  obj.id;
              this.edprid =  obj.id;
              this.softtype = 'Hospital';
              this.ProfilePic =  obj.ProfilePic;
              if(this.ProfilePic == "" || this.ProfilePic == null)
              {
                this.ProfilePic= "assets/img/hospital.png";
              }
              this.UserName =  obj.UserName;
              if(this.UserName == "" || this.UserName == null)
              {
                this.UserName = "User Name";
              }
              this.internationalNumber =  obj.internationalNumber;
              //alert(this.internationalNumber);

              this.appPages = [
                {
                  title: 'Dashboard',
                  url: '/app/tabs/hospitaldashboard',
                  icon: 'flower'
                },
                {
                  title: 'Appointment',
                  url: '/app/tabs/hospschedule',
                  icon: 'calendar',
                  show: this.HospitalRegistration
                },
                {
                  title: 'Billing',
                  url: '/app/tabs/hosp-billinglist',
                  icon: 'receipt',
                  show: this.HospLabRegistration
                },
                {
                  title: 'Prescription',
                  url: '/app/tabs/hosp-prescriptionlist',
                  icon: 'newspaper',
                  show: this.HospitalRegistration
                },
                {
                  title: 'Patient',
                  url: '/app/tabs/hosp-patientlist',
                  icon: 'people-circle',
                  show: this.HospLabRegistration
                },
                {
                  title: 'Doctors',
                  url: '/app/tabs/hosp-doctorslist',
                  icon: 'id-card',
                  show: this.HospitalRegistration
                },
                {
                  title: 'Hospital',
                  url: '#',
                  icon: 'business',
                  show: this.HospitalRegistration,
                  hasChild: true,
                  expanded: false,
                  subPages: [
                    { title: 'subtest1', url: '/app/tabs/hospschedule' , icon: 'settings' },
                    { title: 'subtest2', url: '/app/tabs/hosp-hospitallist' , icon: 'planet' },
                    { title: 'subtest3', url: '/app/tabs/hosp-laboratorylist' , icon: 'flask' },
                    { title: 'Hospital', url: '/app/tabs/hosp-hospitallist' , icon: 'business' }
                  ]
                },
                {
                  title: 'Pharmacy',
                  url: '#',
                  icon: 'bandage',
                  show: this.PharmacyRegistration,
                  hasChild: true,
                  expanded: false,
                  subPages: [
                    { title: 'subtest1', url: '/app/tabs/hospschedule' , icon: 'settings' },
                    { title: 'subtest2', url: '/app/tabs/hosp-hospitallist' , icon: 'planet' },
                    { title: 'subtest3', url: '/app/tabs/hosp-laboratorylist' , icon: 'flask' },
                    { title: 'Pharmacy', url: '/app/tabs/hosp-pharmacylist' , icon: 'bandage' }
                  ]
                },
                {
                  title: 'Laboratory',
                  url: '#',
                  icon: 'flask',
                  show: this.LaboratoryRegistration,
                  hasChild: true,
                  expanded: false,
                  subPages: [
                    { title: 'subtest1', url: '/app/tabs/hospschedule' , icon: 'settings' },
                    { title: 'subtest2', url: '/app/tabs/hosp-hospitallist' , icon: 'planet' },
                    { title: 'subtest3', url: '/app/tabs/hosp-laboratorylist' , icon: 'flask' },
                    { title: 'Laboratory', url: '/app/tabs/hosp-laboratorylist' , icon: 'flask' }
                  ]
                },
                {
                  title: 'Advertisement',
                  url: '/app/tabs/hosp-advertisementsearchlist',
                  icon: 'planet'
                },
                {
                  title: 'Settings',
                  url: '/app/tabs/settings',
                  icon: 'settings'
                }
            
              ];

                 

          }else{
              this.router.navigate(['/signin']);
          }             
    }

    if(this.currentsoftware == 4)
    {
          if (localStorage.getItem('Representativeuser')) { 
            var res = localStorage.getItem('Representativeuser');
            var obj = JSON.parse(res);
            this.edprid =  obj.id;
            this.edprid =  obj.id;
            this.softtype = 'Representative';
            this.ProfilePic =  obj.ProfilePic;
            if(this.ProfilePic == "" || this.ProfilePic == null)
            {
              this.ProfilePic= "assets/images/profile/avatar1.png";
            }
            this.UserName =  obj.UserName;
            if(this.UserName == "" || this.UserName == null)
            {
              this.UserName = "User Name";
            }
            this.internationalNumber =  obj.internationalNumber;
            //alert(this.internationalNumber);

            this.appPages = [
              {
                title: 'Dashboard',
                url: '/app/tabs/representativedashboard',
                icon: 'flower'
              },
              {
                title: 'Earnings',
                url: '#',
                icon: 'cash',
                show: this.LaboratoryRegistration,
                hasChild: true,
                expanded: false,
                subPages: [
                  { title: 'Consultation', url: '/app/tabs/consultationearnings' , icon: 'woman' },
                  { title: 'Hospital', url: '/app/tabs/hosphospitalearnings' , icon: 'newspaper' },
                  { title: 'Pharmacy', url: '/app/tabs/pharmacyearnings' , icon: 'thermometer' },
                  { title: 'Laboratory', url: '/app/tabs/laboratoryearnings' , icon: 'flash' },
                  { title: 'Advertisement', url: '/app/tabs/advertisementearnings' , icon: 'earth' },
                  { title: 'Software', url: '/app/tabs/admindepartmentlist' , icon: 'tv' }
                ]
              },
              {
                title: 'Add Doctors',
                url: '/app/tabs/adddoctorslist',
                icon: 'person-add'
              },

              {
                title: 'Add Institution',
                url: '/app/tabs/addinstitutionlist',
                icon: 'newspaper'
              },

              {
                title: 'Doctors',
                url: '/app/tabs/rep-doctorslist',
                icon: 'people'
              },
              {
                title: 'Hospital',
                url: '/app/tabs/rep-hospitallist',
                icon: 'business'
              },
              {
                title: 'Pharmacy',
                url: '/app/tabs/rep-pharmacylist',
                icon: 'bandage'
              },
              {
                title: 'Laboratory',
                url: '/app/tabs/rep-laboratorylist',
                icon: 'flask'
              },
              {
                title: 'Advertisement',
                url: '/app/tabs/rep-advertisementsearchlist',
                icon: 'earth'
              }
              
          
            ];


        }else{
            this.router.navigate(['/signin']);
        }
    }




    if(this.currentsoftware == 5)
    {
          if (localStorage.getItem('Accountsuser')) { 
            var res = localStorage.getItem('Accountsuser');
            var obj = JSON.parse(res);
            this.edprid =  obj.id;
            this.edprid =  obj.id;
            this.softtype = 'Accounts';
            this.ProfilePic =  obj.ProfilePic;
            if(this.ProfilePic == "" || this.ProfilePic == null)
            {
              this.ProfilePic= "assets/images/profile/avatar1.png";
            }
            this.UserName =  obj.UserName;
            if(this.UserName == "" || this.UserName == null)
            {
              this.UserName = "User Name";
            }
            this.internationalNumber =  obj.internationalNumber;
            //alert(this.internationalNumber);

            this.appPages = [
              {
                title: 'Dashboard',
                url: '/app/tabs/accountsuser',
                icon: 'flower'
              },
              {
                title: 'Laboratory',
                url: '/app/tabs/laboratorylist',
                icon: 'flask'
              },

              {
                title: 'Income',
                url: '#',
                icon: 'globe',
                show: this.LaboratoryRegistration,
                hasChild: true,
                expanded: false,
                subPages: [
                  { title: 'Web Call', url: '/app/tabs/callamount' , icon: 'cash' },
                  { title: 'Call all transactions', url: '/app/tabs/calltransaction' , icon: 'bar-chart' },
                  { title: 'Software', url: '/app/tabs/softamount' , icon: 'card' },
                  { title: 'Software all transactions', url: '/app/tabs/softtransaction' , icon: 'barcode' },
                  { title: 'Advertisement', url: '/app/tabs/adsamount' , icon: 'cash' },
                  { title: 'Ads all transactions', url: '/app/tabs/adstransaction' , icon: 'radio' }
                ]
              },
          
            ];


        }else{
            this.router.navigate(['/signin']);
        }
    }



    
    if(this.currentsoftware == 6)
    {
          if (localStorage.getItem('Adminuser')) { 
            var res = localStorage.getItem('Adminuser');
            var obj = JSON.parse(res);
            this.edprid =  obj.id;
            this.edprid =  obj.id;
            this.softtype = 'Admin';
            this.ProfilePic =  obj.ProfilePic;
            if(this.ProfilePic == "" || this.ProfilePic == null)
            {
              this.ProfilePic= "assets/images/profile/avatar1.png";
            }
            this.UserName =  obj.UserName;
            if(this.UserName == "" || this.UserName == null)
            {
              this.UserName = "User Name";
            }
            this.internationalNumber =  obj.internationalNumber;
            //alert(this.internationalNumber);

            this.appPages = [
              {
                title: 'Dashboard',
                url: '/app/tabs/adminuser',
                icon: 'flower'
              },

              {
                title: 'Settings',
                url: '#',
                icon: 'settings',
                show: this.LaboratoryRegistration,
                hasChild: true,
                expanded: false,
                subPages: [
                  { title: 'Departments', url: '/app/tabs/admindepartmentlist' , icon: 'barcode' },
                  { title: 'Subscription', url: '/app/tabs/subscriptionlist' , icon: 'easel' },
                  { title: 'Price Master', url: '/app/tabs/pricemasterlist' , icon: 'cash' },
                  { title: 'Ads Price', url: '/app/tabs/adspricelist' , icon: 'card' },
                  { title: 'Template', url: '/app/tabs/templatelist' , icon: 'document-lock' }
                ]
              },

              {
                title: 'Chat',
                url: '/app/tabs/chatbotlist',
                icon: 'chatbubbles'
              },

              {
                title: 'Help',
                url: '/app/tabs/helplist',
                icon: 'information-circle'
              },

              {
                title: 'Version Control',
                url: '/app/tabs/settingslist',
                icon: 'cog'
              }
          
          
            ];


        }else{
            this.router.navigate(['/signin']);
        }
    }






  }
  
  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }




  HospitalRegistration: boolean;
  patientregistration: boolean;
  PharmacyRegistration: boolean;
  LaboratoryRegistration: boolean;

  HospLabRegistration: boolean;

hospitalsettings(){


  if (localStorage.getItem('Hospitaluser')) { 
    var res = localStorage.getItem('Hospitaluser');
    var obj = JSON.parse(res);   
  }else{
    this.router.navigate(['/signin']);
  }   

  this.http.post( this.apiURL + 'appsettings.php?Control=Selectsettings', {
    hosuserid:  obj.id,
    LoginType: "Selectsettings"
  })
  .subscribe(
    (res: any) => {
          let kkk = JSON.stringify(res);
          var obj = JSON.parse(kkk);

          if(obj[0].HospitalRegistration == 0){ this.HospitalRegistration = true;}else{this.HospitalRegistration = false;}
          if(obj[0].patientregistration == 0){ this.patientregistration = true;}else{this.patientregistration = false;}
          if(obj[0].PharmacyRegistration == 0){ this.PharmacyRegistration = true;}else{this.PharmacyRegistration = false;}
          if(obj[0].LaboratoryRegistration == 0){ this.LaboratoryRegistration = true;}else{this.LaboratoryRegistration = false;}
    
          //alert(this.LaboratoryRegistration);
          this.HospLabRegistration = true;
          if(obj[0].LaboratoryRegistration == 1 )
          { 
            this.HospLabRegistration = false;
          }
          if( obj[0].HospitalRegistration == 1 )
          { 
            this.HospLabRegistration = false;
          }

          this. checksoftwareandUser();
    },
    err => {
      console.log('Error occured');
    }
  ); 




}










toggle(item) {
    if(item.hasChild == true)
    {
        item.expanded = !item.expanded;
    }
}


  initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
     
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
   // this.userData.logout().then(() => {
   //   return this.router.navigateByUrl('/app/tabs/schedule');
   //});

    this.presentAlertConfirm();

  }


  currentsoftware = GlobalConstants.currentsoftware;
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Logout!',
      message: 'Are you sure you want to <strong>Logout</strong>!!!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Logout',
          id: 'confirm-button',
          handler: () => {

            if(this.currentsoftware == 1)
            {
              localStorage.removeItem('user');
              this.router.navigate(['/login']);
              //console.log('Confirm Okay');
            }

            if(this.currentsoftware == 2)
            {
              localStorage.removeItem('Doctoruser');
              this.router.navigate(['/login']);
              //console.log('Confirm Okay');
            }

            if(this.currentsoftware == 3)
            {
              localStorage.removeItem('Hospitaluser');
              this.router.navigate(['/login']);
              //console.log('Confirm Okay');
            }

            if(this.currentsoftware == 4)
            {
              localStorage.removeItem('Representativeuser');
              this.router.navigate(['/login']);
              //console.log('Confirm Okay');
            }

            if(this.currentsoftware == 5)
            {
              localStorage.removeItem('Accountsuser');
              this.router.navigate(['/login']);
              //console.log('Confirm Okay');
            }

            if(this.currentsoftware == 6)
            {
              localStorage.removeItem('Adminuser');
              this.router.navigate(['/login']);
              //console.log('Confirm Okay');
            }

// 1 - Patient Login
// 2 - Doctor Login
// 3 - Hospital Login
// 4 - Representative  Login
// 5 - Accounts Login
// 6 - Admin Login
          }
        }
      ]
    });

    await alert.present();
  }



  openTutorial() {
   // this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }


  styleScrollbars(elmt: any) {
    const stylesheet = `
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: #666677;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: linear-gradient(var(--ion-color-light-tint), var(--ion-color-light));
        border: 2px solid #666677;
      }
      ::-webkit-scrollbar-thumb:hover {
      }
    `;

    const styleElmt = elmt.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      elmt.shadowRoot.appendChild(barStyle);
    }
  }

  
  styleScrollbars1(elmt: any) {
    const stylesheet = `
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: #666677;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: linear-gradient(var(--ion-color-light-tint), var(--ion-color-light));
        border: 2px solid #666677;
      }
      ::-webkit-scrollbar-thumb:hover {
      }
    `;

    const styleElmt = elmt.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      elmt.shadowRoot.appendChild(barStyle);
    }
  }

 // ngAfterViewInit() {
  //  const myContent = document.querySelector('#my-content');
  //  this.styleScrollbars(myContent);

 //   const myContent1 = document.querySelector('#my-content1');
  //  this.styleScrollbars1(myContent1);
  //}


  goToEditProgile() {
    this.router.navigateByUrl('/tutorial');
  }














  ngAfterViewInit() {

   // const myContent1 = document.querySelector('#my-content1');
   // this.styleScrollbars1(myContent1);
      // This element never changes.
      let ionapp = document.getElementsByTagName("ion-app")[0];
  
      window.addEventListener('keyboardDidShow', async (event) => {
          // Move ion-app up, to give room for keyboard
          let kbHeight: number = event["keyboardHeight"];
          let viewportHeight: number = $(window).height();
          let inputFieldOffsetFromBottomViewPort: number = viewportHeight - $(':focus')[0].getBoundingClientRect().bottom;
          let inputScrollPixels = kbHeight - inputFieldOffsetFromBottomViewPort;
  
          // Set margin to give space for native keyboard.
          ionapp.style["margin-bottom"] = kbHeight.toString() + "px";
  
          // But this diminishes ion-content and may hide the input field...
          if (inputScrollPixels > 0) {
              // ...so, get the ionScroll element from ion-content and scroll correspondingly
              // The current ion-content element is always the last. If there are tabs or other hidden ion-content elements, they will go above.
              let ionScroll = await $("ion-content").last()[0].getScrollElement();
              setTimeout(() => {
                  $(ionScroll).animate({
                      scrollTop: ionScroll.scrollTop + inputScrollPixels
                  }, 300);
              }, 300); // Matches scroll animation from css.
          }
      });
      window.addEventListener('keyboardDidHide', () => {
          // Move ion-app down again
          // Scroll not necessary.
          ionapp.style["margin-bottom"] = "0px";
      });
  }












































  
  CurrentDateString : string = '';

  

  location: {

    phonenumber: string, 
    visittype: string, 

    id: string, 
    type: string, 
    hostname: string, 
    carriername: string, 
    carriermcc: string, 
    carriermnc: string, 
    companydomain: string, 
    companyname: string, 
    companytype: string, 
    connectiondomain: string, 
    connectionorganization: string, 
    connectionroute: string, 
    connectiontype: string, 
    currencycode: string, 
    currencyname: string, 
    currencyname_nativeid: string, 
    currencyplural: string, 
    currencyplural_native: string,  
    locationcontinentcode: string, 
    locationcontinentname: string, 
    locationcountryarea: string, 
    locationcountrycalling_code: string, 
    locationcountrycapital: string, 
    locationcountrycode: string, 
    locationcountryname: string, 
    locationcountrypopulation: string, 
    locationcountrypopulation_density: string, 
    locationcountrytld: string, 
    locationregioncode: string, 
    locationregionname: string, 


    locationcity: string, 
    locationpostal: string, 
    locationlatitude: string, 

 
    locationlongitude: string, 
    locationlanguagecode: string, 
    locationlanguagename: string, 

 
    locationlanguagenative: string, 
    time_zoneid: string, 
    time_zoneabbreviation: string, 

 
    time_zonecurrent_time: string, 
    time_zonename: string, 
    user_agentname: string, 

 
    user_agenttype: string, 
    user_agentdevicebrand: string, 
    user_agentdevicename: string, 

  
    user_agentdevicetype: string, 
    user_agentenginename: string, 
    user_agentenginetype: string, 

 
    user_agentosname: string, 
    user_agentostype: string, 
    user_agentosversion: string, 

  }[] = [];




 count = '';
   totalCount = '';
   loadhitcount(){

    this.http.post( this.apiURL + 'adminsettings.php?Control=SelectAdminHitCount', {
      SelectDepartment: "SelectAdminHitCount",
      softwareid: this.softwareido,
      version: this.version
    })
    .subscribe(
      res => {
         let kkk = JSON.stringify(res);
         var obj = JSON.parse(kkk);
  
       this.count = obj.count;
       this.totalCount = obj.totalCount;
            
      },
      err => {
        //console.log('Error occured');
      }
    ); 
  
  }











  userIP: string = '';
  loadIp() {
    this.http.get('https://jsonip.com').subscribe(
      (value:any) => {
        console.log(value);
        this.userIP = value.ip;
        localStorage.setItem('currentIP', this.userIP);

        this.verification();
      },
      (error) => {
        console.log(error);
      }
    );
  }


  verification(){

    var storedIP = localStorage.getItem('currentIP');
    var storedDate = localStorage.getItem('storedDate');
    var CurrentDate = new Date();

    let Current_date =this.datepipe.transform(CurrentDate, 'yyyy-MM-dd');
    if(Current_date == null){
     Current_date = "";
    }
    this.CurrentDateString =   Current_date;
   // alert('cccccccc');
    if(storedDate != Current_date)
    {
     localStorage.setItem('storedDate', Current_date);
    // alert('aaaaaaaaaaa');
    this.getalldetails();
    }

    if(storedIP != this.userIP)
    {
     localStorage.setItem('currentIP', this.userIP);
    // alert('bbbbbbbbbbbbb');
    this.getalldetails();
    }

  //  this.getalldetails();
   // alert(storedIP + '   --   ' + this.userIP + '   --   ' +  storedDate + '   --   ' +  Current_date );


  }

getalldetails(){
    //https://api.ipregistry.co/?key=732qwjttszflsj0r
  
  this.http.get<any>('https://api.ipregistry.co/?key=' + this.ipregistryAPIkey).subscribe({
        next: data => {
           // this.totalAngularPackages = data.total;

     //      var location = data.location; //['country']['name'];
  // var country = location.country;
   var country =  data['location']['country']['name'];

   var ip =  data['ip'];
   var type =  data['type'];
   var hostname =  data['hostname'];

   var carriername =  data['carrier']['name'];
   var carriermcc =  data['carrier']['mcc'];
   var carriermnc =  data['carrier']['mnc'];

   var companydomain =  data['company']['domain'];
   var companyname =  data['company']['name'];
   var companytype =  data['company']['type'];

   var connectiondomain =  data['connection']['domain'];
   var connectionorganization =  data['connection']['organization'];
   var connectionroute =  data['connection']['route'];
   var connectiontype =  data['connection']['type'];

   var currencycode =  data['currency']['code'];
   var currencyname =  data['currency']['name'];
   var currencyname_native =  data['currency']['name_native'];
   var currencyplural =  data['currency']['plural'];
   var currencyplural_native =  data['currency']['plural_native'];


    var locationcontinentcode =  data['location']['continent']['code'];
    var locationcontinentname =  data['location']['continent']['name'];


    var locationcountryarea =  data['location']['country']['area'];
    var locationcountrycalling_code =  data['location']['country']['calling_code'];
    var locationcountrycapital =  data['location']['country']['capital'];
    var locationcountrycode =  data['location']['country']['code'];
    var locationcountryname =  data['location']['country']['name'];
    var locationcountrypopulation =  data['location']['country']['population'];
    var locationcountrypopulation_density =  data['location']['country']['population_density'];
    var locationcountrytld =  data['location']['country']['tld'];

    var locationregioncode =  data['location']['region']['code'];
    var locationregionname =  data['location']['region']['name'];

    var locationcity =  data['location']['city'];
    var locationpostal =  data['location']['postal'];
    var locationlatitude =  data['location']['latitude'];
    var locationlongitude =  data['location']['longitude'];

    var locationlanguagecode =  data['location']['language']['code'];
    var locationlanguagename =  data['location']['language']['name'];
    var locationlanguagenative =  data['location']['language']['native'];

    var time_zoneid =  data['time_zone']['id'];
    var time_zoneabbreviation =  data['time_zone']['abbreviation'];
    var time_zonecurrent_time =  data['time_zone']['current_time'];
    var time_zonename =  data['time_zone']['name'];

    var user_agentname =  data['user_agent']['name'];
    var user_agenttype =  data['user_agent']['type'];
 
      var user_agentdevicebrand =  data['user_agent']['device']['brand'];
      var user_agentdevicename =  data['user_agent']['device']['name'];
      var user_agentdevicetype =  data['user_agent']['device']['type'];


      var user_agentenginename =  data['user_agent']['engine']['name'];
      var user_agentenginetype =  data['user_agent']['engine']['type'];


     
      var user_agentosname =  data['user_agent']['os']['name'];
      var user_agentostype =  data['user_agent']['os']['type'];
      var user_agentosversion =  data['user_agent']['os']['version'];

      var phonenumber ='';
       var   visittype ='';

      this.location.push({


          phonenumber: phonenumber, 
          visittype: visittype, 
      
          id: ip, 
          type: type, 
          hostname: hostname, 
          carriername: carriername, 
          carriermcc: carriermcc, 
          carriermnc: carriermnc, 
          companydomain: companydomain, 
          companyname: companyname, 
          companytype: companytype, 
          connectiondomain: connectiondomain, 
          connectionorganization: connectionorganization, 
          connectionroute: connectionroute, 
          connectiontype: connectiontype, 
          currencycode: currencycode, 
          currencyname: currencyname, 
          currencyname_nativeid: currencyname_native ,
          currencyplural: currencyplural, 
          currencyplural_native: currencyplural_native,  
          locationcontinentcode: locationcontinentcode, 
          locationcontinentname: locationcontinentname, 
          locationcountryarea: locationcountryarea, 
          locationcountrycalling_code: locationcountrycalling_code, 
          locationcountrycapital: locationcountrycapital, 
          locationcountrycode: locationcountrycode, 
          locationcountryname: locationcountryname, 
          locationcountrypopulation: locationcountrypopulation, 
          locationcountrypopulation_density: locationcountrypopulation_density, 
          locationcountrytld: locationcountrytld, 
          locationregioncode: locationregioncode, 
          locationregionname: locationregionname, 
      
      
          locationcity: locationcity, 
          locationpostal: locationpostal, 
          locationlatitude: locationlatitude, 
      
       
          locationlongitude: locationlongitude, 
          locationlanguagecode: locationlanguagecode, 
          locationlanguagename: locationlanguagename, 
      
       
          locationlanguagenative: locationlanguagenative, 
          time_zoneid: time_zoneid, 
          time_zoneabbreviation: time_zoneabbreviation, 
      
       
          time_zonecurrent_time: time_zonecurrent_time, 
          time_zonename: time_zonename, 
          user_agentname: user_agentname, 
      
       
          user_agenttype: user_agenttype, 
          user_agentdevicebrand: user_agentdevicebrand, 
          user_agentdevicename: user_agentdevicename, 
      
        
          user_agentdevicetype: user_agentdevicetype, 
          user_agentenginename: user_agentenginename, 
          user_agentenginetype: user_agentenginetype, 
      
       
          user_agentosname: user_agentosname, 
          user_agentostype: user_agentostype, 
          user_agentosversion: user_agentosversion, 
      
  });

this.savedetails();

               },
        error: error => {
           // this.errorMessage = error.message;
            console.error('There was an error!', error);
        }
    })

}


softwareido = GlobalConstants.currentsoftware;
version = GlobalConstants.version;
ipregistryAPIkey = "";
loadapplicationsettings(){

  this.http.post( this.apiURL + 'adminsettings.php?Control=SelectMasterSettings', {
    SelectDepartment: "SelectMasterSettings",
    softwareid: this.softwareido,
    version: this.version
  })
  .subscribe(
    res => {
       let kkk = JSON.stringify(res);
       var obj = JSON.parse(kkk);

      //  this.softid = obj[0].id;
      //  this.software = obj[0].software;
      //  this.softwareid = obj[0].softwareid;
      //  this.versionv = obj[0].version;

      // this.dashboardmessage = obj[0].dashboardmessage;
     //  this.androiddownload = obj[0].androiddownload;
     //  this.iosdownload = obj[0].iosdownload;
     //  this.adminweburl = obj[0].adminweburl;
      
     //  this.weburl = obj[0].weburl;
    
     //  this.Vimeourl  = obj[0].Vimeo;
     //  this.Instagramurl  = obj[0].Instagram;
     //  this.Twitterurl  = obj[0].Twitter;
     //  this.Facebookurl  = obj[0].Facebook;
     //  this.Youtubeurl  = obj[0].Youtube;

     this.ipregistryAPIkey = obj[0].ipregistryAPIkey;
    // this.loadhitcount();

    this.loadIp();
    },
    err => {
      //console.log('Error occured');
    }
  ); 

}





savedetails(){

  this.http.post( this.apiURL + 'tracking.php?Control=saveBJApptechwebsitelocation', {
   
    softwareid: this.softwareido,
    phonenumber: this.location[0].phonenumber, 
    visittype: this.location[0].visittype, 

    ip: this.location[0].id, 
    type: this.location[0].type, 
    hostname: this.location[0].hostname, 
    carriername: this.location[0].carriername, 
    carriermcc: this.location[0].carriermcc, 
    carriermnc: this.location[0].carriermnc, 
    companydomain: this.location[0].companydomain, 
    companyname: this.location[0].companyname, 
    companytype: this.location[0].companytype, 
    connectiondomain: this.location[0].connectiondomain, 
    connectionorganization: this.location[0].connectionorganization, 
    connectionroute: this.location[0].connectionroute, 
    connectiontype: this.location[0].connectiontype, 
    currencycode: this.location[0].currencycode, 
    currencyname: this.location[0].currencyname, 
    currencyname_nativeid: this.location[0].currencyname_nativeid ,
    currencyplural: this.location[0].currencyplural, 
    currencyplural_native: this.location[0].currencyplural_native,  
    locationcontinentcode: this.location[0].locationcontinentcode, 
    locationcontinentname: this.location[0].locationcontinentname, 
    locationcountryarea: this.location[0].locationcountryarea, 
    locationcountrycalling_code: this.location[0].locationcountrycalling_code, 
    locationcountrycapital: this.location[0].locationcountrycapital, 
    locationcountrycode: this.location[0].locationcountrycode, 
    locationcountryname: this.location[0].locationcountryname, 
    locationcountrypopulation: this.location[0].locationcountrypopulation, 
    locationcountrypopulation_density: this.location[0].locationcountrypopulation_density, 
    locationcountrytld: this.location[0].locationcountrytld, 
    locationregioncode: this.location[0].locationregioncode, 
    locationregionname: this.location[0].locationregionname, 


    locationcity: this.location[0].locationcity, 
    locationpostal: this.location[0].locationpostal, 
    locationlatitude: this.location[0].locationlatitude, 

 
    locationlongitude: this.location[0].locationlongitude, 
    locationlanguagecode: this.location[0].locationlanguagecode, 
    locationlanguagename: this.location[0].locationlanguagename, 

 
    locationlanguagenative: this.location[0].locationlanguagenative, 
    time_zoneid: this.location[0].time_zoneid, 
    time_zoneabbreviation: this.location[0].time_zoneabbreviation, 

 
    time_zonecurrent_time: this.location[0].time_zonecurrent_time, 
    time_zonename: this.location[0].time_zonename, 
    user_agentname: this.location[0].user_agentname, 

 
    user_agenttype: this.location[0].user_agenttype, 
    user_agentdevicebrand: this.location[0].user_agentdevicebrand, 
    user_agentdevicename: this.location[0].user_agentdevicename, 

  
    user_agentdevicetype: this.location[0].user_agentdevicetype, 
    user_agentenginename: this.location[0].user_agentenginename, 
    user_agentenginetype: this.location[0].user_agentenginetype, 

 
    user_agentosname: this.location[0].user_agentosname, 
    user_agentostype: this.location[0].user_agentostype, 
    user_agentosversion: this.location[0].user_agentosversion, 
   
    LoginType: "saveBJApptechwebsitelocation"
  })
  .subscribe(
    res => {
   
       //alert(obj.id);
     

    },
    err => {
      //console.log('Error occured');
   
    }
  );   

}




}

import { Component, OnInit, Input,Output, EventEmitter,Renderer2  } from '@angular/core';

export class GlobalConstants {
  //  public static apiURL: string = "http://localhost:2000/"; // "http://apibjdoctor.bjapptech.com/" ;// "http://localhost:9000/";

  //  public static apiURL: string = "http://apibjdoctor.bjapptech.com/" ;// "http://localhost:9000/";

    public static apiURL: string = "https://apibjdoctor.bjapptech.com/" ;// "http://localhost:9000/";

  //  public static apiURL: string = "http://localhost:2000/"; // "http://apibjdoctor.bjapptech.com/" ;// "http://localhost:9000/";

    public static softwareType: string = "BJ Doctor";//"LIS";
    public static GENERALSETTINGSClass: string = "right-sidebar open";

    public static PAYTMDEVICE: string = "WEB";//"WEB";  //"WAP";


    public static softwareName: string = "BJ Doctor";//"LIS";
  // public static softwareName: string = "BJ Doctor";//"LIS";
  //  public static softwareName: string = "BJ Doctor";//"LIS";
  // public static softwareName: string = "BJ Doctor";//"LIS";
  //  public static softwareName: string = "BJ Doctor";//"LIS";

  




  public static currentsoftware: number = 6; 
// 0 - BJ Apptech Website
// 1 - Patient Login
// 2 - Doctor Login
// 3 - Hospital Login
// 4 - Representative  Login
// 5 - Accounts Login
// 6 - Admin Login


public static version: string = '1.0'; 



  public static welcomescreen: string = "Welcome to Patient Login";

  public static notificationcount: number = 0; 


    private static renderer: Renderer2 ;

    static SIDEBARMENUCOLORS?: string;
    static THEMECOLORS?: string;
   
    static menuhidec: boolean = false;
    static mobilemenuhidec: boolean = false;
   
    static themewhite: string = "";
    static themeblack: string = "";
    static themepurple: string = "";
    static themeblue: string = "";
    static themecyan: string = "";
    static themegreen: string = "";
    static themeorange: string = "";
   



    public static calltheme()
    {
     // alert('gggggggg');
    
      if (localStorage.getItem('theme')) { 
        var theme = localStorage.getItem('theme');
        if(theme == '"Dark"')
        {
          this.THEMECOLORS = "Dark";
        }
        else{
          this.THEMECOLORS = "White";
        }
        this.ThemeColors();
    }else{
      this.THEMECOLORS = "White";
      this.ThemeColors();
    }
    
    
    if (localStorage.getItem('SIDEBARMENUCOLORS')) { 
      var theme = localStorage.getItem('SIDEBARMENUCOLORS');
      if(theme == '"Dark"')
      {
        this.SIDEBARMENUCOLORS = "Dark";
      }
      else{
        this.SIDEBARMENUCOLORS = "White";
      }
      this.sideBarMeneColors();
    }else{
    this.SIDEBARMENUCOLORS = "White";
    this.sideBarMeneColors();
    }
    
    
    
    if (localStorage.getItem('COLORS')) { 
    var theme = localStorage.getItem('COLORS');
    if(theme == '"white"')
    {
      this.callthemewhite();
    }
    else if(theme == '"black"')
    {
      this.callthemeblack();
    }
    else if(theme == '"purple"')
    {
      this.callthemepurple();
    }
    else if(theme == '"blue"')
    {
      this.callthemeblue();
    }
    else if(theme == '"cyan"')
    {
      this.callthemecyan();
    }
    else if(theme == '"green"')
    {
      this.callthemegreen();
    }
    else if(theme == '"orange"')
    {
      this.callthemeorange();
    }
    else{
      this.callthemewhite();
    }
    
    }else{
    this.callthemewhite();
    }
    
    
    }
    
    
    
    




    static  callthemewhite(){

      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themewhite = "actived";
    
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('white'));
    
      this.renderer.addClass(document.body, 'theme-white');
    
    
    }
    static  callthemeblack(){
    
    
      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themeblack = "actived";
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('black'));
    
      this.renderer.addClass(document.body, 'theme-black');
    }
    static  callthemepurple(){
    
    
      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themepurple = "actived";
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('purple'));
    
      this.renderer.addClass(document.body, 'theme-purple');
    }
    static callthemeblue(){
    
    
      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themeblue = "actived";
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
    
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('blue'));
    
      this.renderer.addClass(document.body, 'theme-blue');
    
    }
    static callthemecyan(){
    
    
      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themecyan = "actived";
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
    
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('cyan'));
      this.renderer.addClass(document.body, 'theme-cyan');
    
    }
    static  callthemegreen(){
    
    
      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themegreen = "actived";
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
    
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('green'));
    
      this.renderer.addClass(document.body, 'theme-green');
    
    }
    static callthemeorange(){
    
    
      this.themewhite = "";
      this.themeblack = "";
      this.themepurple = "";
      this.themeblue = "";
      this.themecyan = "";
      this.themegreen = "";
      this.themeorange = "";
    
      this.themeorange = "actived";
    
      this.renderer.removeClass(document.body, 'theme-white');
      this.renderer.removeClass(document.body, 'theme-black');
      this.renderer.removeClass(document.body, 'theme-purple');
      this.renderer.removeClass(document.body, 'theme-blue');
      this.renderer.removeClass(document.body, 'theme-cyan');
      this.renderer.removeClass(document.body, 'theme-green');
      this.renderer.removeClass(document.body, 'theme-orange');
      
    
    
      localStorage.removeItem('COLORS');
      localStorage.setItem('COLORS', JSON.stringify('orange'));
    
      this.renderer.addClass(document.body, 'theme-orange');
    
    }
    
    
    static  sideBarMeneColors(){
      //alert(this.SIDEBARMENUCOLORS);
    
    if(this.SIDEBARMENUCOLORS == "Dark")
    {
      localStorage.removeItem('SIDEBARMENUCOLORS');
      localStorage.setItem('SIDEBARMENUCOLORS', JSON.stringify('Dark'));
      this.renderer.removeClass(document.body, 'menu_light');
      this.renderer.removeClass(document.body, 'logo-white');
    
      this.renderer.addClass(document.body, 'menu_dark');
      this.renderer.addClass(document.body, 'logo-black');
    
    }else{
      localStorage.removeItem('SIDEBARMENUCOLORS');
      localStorage.setItem('SIDEBARMENUCOLORS', JSON.stringify('White'));
      this.renderer.removeClass(document.body, 'menu_dark');
      this.renderer.removeClass(document.body, 'logo-black');
    
      this.renderer.addClass(document.body, 'menu_light');
      this.renderer.addClass(document.body, 'logo-white');
    
    }
    }
    
    static  ThemeColors(){
       //alert(this.THEMECOLORS);
    
       if(this.THEMECOLORS == "Dark")
    {
    
      localStorage.removeItem('theme');
      localStorage.setItem('theme', JSON.stringify('Dark'));
    
      localStorage.removeItem('SIDEBARMENUCOLORS');
      localStorage.setItem('SIDEBARMENUCOLORS', JSON.stringify('Dark'));
    
     // localStorage.removeItem('COLORS');
     // localStorage.setItem('COLORS', JSON.stringify('Dark'));
    
      this.renderer.removeClass(document.body, 'menu_light');
      this.renderer.removeClass(document.body, 'logo-white');
      this.renderer.removeClass(document.body, 'light');
      this.renderer.removeClass(document.body, 'theme-white');
    
      
    
      this.renderer.addClass(document.body, 'menu_dark');
      this.renderer.addClass(document.body, 'logo-black');
      this.renderer.addClass(document.body, 'dark');
      this.renderer.addClass(document.body, 'theme-black');
    
      this.renderer.addClass(document.body, 'submenu-closed');
    
    }else{
    
      
    
      localStorage.removeItem('theme');
      localStorage.setItem('theme', JSON.stringify('White'));
    
      localStorage.removeItem('SIDEBARMENUCOLORS');
      localStorage.setItem('SIDEBARMENUCOLORS', JSON.stringify('White'));
    
      //localStorage.removeItem('COLORS');
      //localStorage.setItem('COLORS', JSON.stringify('White'));
    
    
      this.renderer.removeClass(document.body, 'menu_dark');
      this.renderer.removeClass(document.body, 'logo-black');
      this.renderer.removeClass(document.body, 'dark');
      this.renderer.removeClass(document.body, 'theme-black');
    
    
      this.renderer.addClass(document.body, 'menu_light');
      this.renderer.addClass(document.body, 'logo-white');
      this.renderer.addClass(document.body, 'light');
      this.renderer.addClass(document.body, 'theme-white');
    
      this.renderer.addClass(document.body, 'submenu-closed');
    
    }
    }
    
    
    
    
    
    
    
    
    
    static ThemeColorsbtn(){
      //alert(this.THEMECOLORS);
    
      if(this.THEMECOLORS == "Dark")
    {
    
     localStorage.removeItem('theme');
     localStorage.setItem('theme', JSON.stringify('Dark'));
    
     localStorage.removeItem('SIDEBARMENUCOLORS');
     localStorage.setItem('SIDEBARMENUCOLORS', JSON.stringify('Dark'));
    
     localStorage.removeItem('COLORS');
     localStorage.setItem('COLORS', JSON.stringify('black'));
    
     this.renderer.removeClass(document.body, 'menu_light');
     this.renderer.removeClass(document.body, 'logo-white');
     this.renderer.removeClass(document.body, 'light');
     this.renderer.removeClass(document.body, 'theme-white');
    
     
    
     this.renderer.addClass(document.body, 'menu_dark');
     this.renderer.addClass(document.body, 'logo-black');
     this.renderer.addClass(document.body, 'dark');
     this.renderer.addClass(document.body, 'theme-black');
    
     this.renderer.addClass(document.body, 'submenu-closed');
    
    }else{
    
     
    
     localStorage.removeItem('theme');
     localStorage.setItem('theme', JSON.stringify('White'));
    
     localStorage.removeItem('SIDEBARMENUCOLORS');
     localStorage.setItem('SIDEBARMENUCOLORS', JSON.stringify('White'));
    
     localStorage.removeItem('COLORS');
     localStorage.setItem('COLORS', JSON.stringify('White'));
    
    
     this.renderer.removeClass(document.body, 'menu_dark');
     this.renderer.removeClass(document.body, 'logo-black');
     this.renderer.removeClass(document.body, 'dark');
     this.renderer.removeClass(document.body, 'theme-black');
    
    
     this.renderer.addClass(document.body, 'menu_light');
     this.renderer.addClass(document.body, 'logo-white');
     this.renderer.addClass(document.body, 'light');
     this.renderer.addClass(document.body, 'theme-white');
    
     this.renderer.addClass(document.body, 'submenu-closed');
    
    }
    }
    
    
    
    
    





}
